import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchSasToken = createAsyncThunk(
  "documents/sasToken", 
  async ({ controller }: { controller }, { getState }) => {
   const state = getState() as any;
  const sasTokenExpiry = new URLSearchParams(state.emails.sasToken).get("se");
  // check if token expires in the next five minutes
  if (sasTokenExpiry && new Date(sasTokenExpiry).getTime() > new Date().getTime() + 5 * 60 * 1000) {
    return state.documents.sasToken;
  }
  const response = await axios.get(`/sas-token`);
  const query = '?' + response.data.split('?')[1];
  return query;
});

// Then, handle actions in your reducers:
const documentsSlice = createSlice({
  name: "documents",
  initialState: {
    sasToken: '',
  },
  reducers: {},
  extraReducers: {
    [fetchSasToken.fulfilled.type]: (state, action) => {
      state.sasToken = action.payload;
    },
  },
});

export default documentsSlice.reducer;