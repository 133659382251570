import React, { useContext, useMemo } from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Input, Row } from "antd";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";

import { fetchAllInvoices, fetchInvoiceCounts } from "../store/invoicesSlice";
import { FeatureFlags, FilterOptModel } from "../hooks/featureFlagsProvider";
import { fetchEmailCounts, fetchEmails } from "../store/emailsSlice";
import { fetchSasToken } from "../store/documentsSlice";
import { searchServiceTeams } from "../store/serviceTeamSlice";
import DebounceSelect from "../components/DebounceSelect";
import AssignToModal from "../components/AssignToModal";
import InvoicesTable from "../components/InvoicesTable";
import StatusFilters from "../components/StatusFilters";
import EmailsTable from "../components/EmailsTable";
import { DEBOUNCE_TIME } from "../utils/constants";
import TagInput from "../components/InputWithTags";
import { searchUsers } from "../store/userSlice";
import BlankCard from "../components/BlankCard";
import SearchBar from "../components/SearchBar";
import useSignalR from "../hooks/useSignalr";
import { useAppSelector } from "../store";
import { StatusCounts } from "../utils";
import styled from "styled-components";
import { breakpoint } from "../theme";
import { DocumentTypeEnum } from "../enums/DocumentTypeEnum";

const { Search } = Input;

interface Props {
  envConfig: any;
}

const DivRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;

  @media only screen and ${breakpoint.device.sm} {
    flex-direction: row;
  }
`;

const LeftRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;

  @media only screen and ${breakpoint.device.sm} {
    flex-direction: row;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media only screen and ${breakpoint.device.sm} {
    flex-direction: row;
  }
`;

export interface Filter {
  status?: string;
  search?: string;
  count: number;
  page: number;
  sortColumn: string;
  sortDirection: string;
  refreshStamp: string;
  statusFilter?: number;
  statusId?: number;
  emailSubject?: string;
  emailAddress?: string;
  assignedTo?: string;
  customerName?: string;
  st?: string;
  expand?: string;
  docType?: DocumentTypeEnum;
}

export interface BulkActions {
  emails: React.Key[];
  invoices: React.Key[];
  isModalOpen: boolean;
  hasSelected: boolean;
}

const SearchStyle = {
  margin: 5,
  display: "flex",
  flex: 1,
};

function Dashboard({ envConfig }: Props) {
  const searchFromLocation = useLocation().search;
  const urlParams = useMemo(() => new URLSearchParams(searchFromLocation), [searchFromLocation]);
  const dispatch = useDispatch();
  const emailStatusCounts = useAppSelector((state) => state.emails.statusCounts);
  const invoiceStatusCounts: StatusCounts[] = useAppSelector((state) => state.invoices.statusCounts);
  const { features } = useContext(FeatureFlags);
  const showServiceTeamDropdownSearch = features.showServiceTeamDropdownSearch;

  const [bulkActions, setBulkActions] = useState<BulkActions>({
    emails: [],
    invoices: [],
    isModalOpen: false,
    hasSelected: false,
  });

  const [filter, setFilter] = useState<Filter | null>(null);
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [serviceTeamTags, setServiceTeamTags] = useState<string[]>([]);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [assignedTo, setAssignedTo] = useState<any>(null);
  const [documentType, setDocumentType] = useState<DocumentTypeEnum | null>(null);

  useEffect(() => {
    let filter = {};
    const filterOpts = features?.filterOptions;

    if (filterOpts) {
      filterOpts.forEach((f: FilterOptModel) => {
        let paramValue;
        if (f.storage) {
          paramValue = urlParams.get(f.key) || sessionStorage.getItem(`${f.key}`) || f.value;
        } else {
          paramValue = urlParams.get(f.key) || f.value;
        }

        if (paramValue) {
          filter[f.label] = paramValue;
        }
      });
    }
    const searchValue = urlParams.get("search");
    if (searchValue) {
      setSearchTags(searchValue.split(","));
    }
    const serviceTeamValue = urlParams.get("st");
    if (serviceTeamValue) {
      setServiceTeamTags(serviceTeamValue.split(","));
    }

    const documentTypeValue = urlParams.get("docType") || sessionStorage.getItem("docType");
    if (documentTypeValue) {
      setDocumentType(documentTypeValue as DocumentTypeEnum);
      filter["docType"] = documentTypeValue as DocumentTypeEnum;
    }

    if (urlParams.get("emailAddress")) setEmailAddress(urlParams.get("emailAddress"));
    if (urlParams.get("emailSubject")) setEmailSubject(urlParams.get("emailSubject"));
    if (urlParams.get("assignedTo")) setAssignedTo(sessionStorage.getItem(`assignedToName`));

    setFilter(() => filter as Filter);
  }, [features, urlParams]);

  useEffect(() => {
    const controller = new AbortController();
    if (!sessionStorage.getItem("sasToken")) {
      dispatch(fetchSasToken({ controller }));
    }
    if (features?.pageLayout.displayEmailList) {
      dispatch(fetchEmailCounts({ controller }));
      dispatch(fetchEmails({ filter, controller }));
    } else {
      dispatch(fetchInvoiceCounts({ controller }));
      dispatch(fetchAllInvoices({ filter, controller }));
    }

    return () => {
      controller.abort();
    }
  }, [filter, dispatch, features]);

  const onFilterChange = useCallback((key: keyof Filter, value?: number | string | DocumentTypeEnum) => {
    const newState = { [key]: value };
    if (key !== "page" && key !== "sortDirection" && key !== "sortColumn") {
      newState.page = 1;
    }
    setFilter((state) => ({ ...state, ...newState }));
  }, []);

  const onFilterChangeDebounced = useMemo(() => debounce(onFilterChange, DEBOUNCE_TIME), [onFilterChange]);

  useEffect(() => {
    return () => {
      onFilterChangeDebounced.cancel();
    };
  }, [onFilterChangeDebounced]);

  const handleOnSearchTags = useCallback(
    (tags: string[]) => {
      setSearchTags(tags);
      const criteria = tags.join(",");
      onFilterChange("search", criteria);
    },
    [setSearchTags, onFilterChange]
  );

  const handleOnSearchServiceTeamTags = useCallback(
    (tags: string[]) => {
      setServiceTeamTags(tags);
      const criteria = tags.join(",");
      onFilterChange("st", criteria);
    },
    [setServiceTeamTags, onFilterChange]
  );

  const handleDocumentTypeChange = (value: DocumentTypeEnum | null) => {
    setDocumentType(value);
    if (value) {
      onFilterChange("docType", value);
      sessionStorage.setItem("docType", value);
    } else {
      onFilterChange("docType", undefined);
      sessionStorage.removeItem("docType");
    }
  };

  const filterByUser = (value: any) => {
    if (value) {
      setAssignedTo(value?.label);
      onFilterChange("assignedTo", value?.key);
      sessionStorage.setItem(`assignedToName`, value?.label);
    } else {
      setAssignedTo(null);
      onFilterChange("assignedTo", undefined);
      sessionStorage.removeItem("assignedToName");
    }
  };

  const filterByServiceTeam = (value: any) => {
    if (value) {
      onFilterChange("st", value?.key);
    } else {
      onFilterChange("st", undefined);
    }
  };

  const handleChange = () => {
    setBulkActions((state) => ({ ...state, isModalOpen: true }));
  };

  const onSelectionChange = (value: React.Key[]) => {
    if (features?.pageLayout.displayEmailList) setBulkActions((state) => ({ ...state, emails: value, hasSelected: value.length > 0 }));
    else setBulkActions((state) => ({ ...state, invoices: value, hasSelected: value.length > 0 }));
  };

  const onClosePopup = (refresh: boolean) => {
    if (refresh) {
      const refreshStamp = `${new Date().getTime()}`;
      setFilter((state) => ({ ...state, refreshStamp: refreshStamp }));
      setBulkActions((state) => ({ ...state, emails: [], action: "Select", isModalOpen: false, hasSelected: false }));
    } else {
      setBulkActions((state) => ({ ...state, action: "Select", isModalOpen: false }));
    }
  };

  const fetchUserList = async (criteria: string): Promise<any[]> => {
    return searchUsers(criteria).then((res: any) => {
      return res;
    });
  };

  const fetchServiceTeamList = async (criteria: string): Promise<any[]> => {
    return searchServiceTeams(criteria).then((res: any) => {
      return res;
    });
  };

  useSignalR({ envConfig, id: null, readOnly: null });

  const isGlobalSearch = features?.isGlobalSearchBox || false;
  const displayEmailList = features?.pageLayout?.displayEmailList || false;

  if (features?.pageLayout.useDefaultLayout) {
    return (
      <Layout>
        <div style={{ flex: 1, overflow: "hidden" }}>
          {
            <BlankCard>
              <DivRow>
                <StatusFilters
                  statusCounts={features?.pageLayout.displayEmailList ? emailStatusCounts : invoiceStatusCounts}
                  filter={filter}
                  onFilterChange={onFilterChange}
                />
              </DivRow>
            </BlankCard>
          }
          <BlankCard>
            {!isGlobalSearch && (
              <DivRow>
                <Search
                  style={SearchStyle}
                  onChange={({ target }) => {
                    setEmailAddress(target.value);
                    onFilterChangeDebounced("emailAddress", target.value);
                  }}
                  defaultValue={urlParams.get("fileName")}
                  allowClear
                  placeholder="Email"
                  size="large"
                  loading={false}
                  value={emailAddress}
                />
                <Search
                  style={SearchStyle}
                  onChange={({ target }) => {
                    setEmailSubject(target.value);
                    onFilterChangeDebounced("emailSubject", target.value);
                  }}
                  defaultValue={urlParams.get("emailSubject")}
                  allowClear
                  placeholder="Subject"
                  size="large"
                  loading={false}
                  value={emailSubject}
                />
                <DebounceSelect style={SearchStyle} selected={assignedTo} placeholder="Assigned To" fetchOptions={fetchUserList} onClear={() => filterByUser(null)} onChange={target => { if (target) filterByUser(target); }} />
                {showServiceTeamDropdownSearch && (
                  <DebounceSelect style={SearchStyle} selected={assignedTo} size="large" placeholder="Assigned To" fetchOptions={fetchUserList} onClear={() => filterByUser(null)} onChange={target => { if (target) filterByUser(target); }} />
                )}
              </DivRow>
            )}
            {isGlobalSearch && (
              <DivRow>
                <TagInput placeholder="Search" style={SearchStyle} value={searchTags} onSearch={handleOnSearchTags} />
                {showServiceTeamDropdownSearch && (
                  <DebounceSelect style={SearchStyle} size="middle" placeholder="Service Team" fetchOptions={fetchServiceTeamList} onChange={filterByServiceTeam} />
                )}
              </DivRow>
            )}
          </BlankCard>
          <BlankCard>
            <LeftRow>
              {features?.isAssignToEnabled && (
                <Button type="default" disabled={!bulkActions.hasSelected} onClick={handleChange}>
                  Assign To
                </Button>
              )}
            </LeftRow>
            <Row className="pt-20px">
              <Col span={24}>
                {displayEmailList && (
                  <EmailsTable
                    filter={filter}
                    onFilterChange={onFilterChange}
                    selectedEmails={bulkActions.emails}
                    onSelectionChange={onSelectionChange}
                  />
                )}
                {!displayEmailList && (
                  <InvoicesTable
                    filter={filter}
                    onFilterChange={onFilterChange}
                    selectedEmails={bulkActions.emails}
                    onSelectionChange={onSelectionChange}
                  />
                )}
              </Col>
            </Row>
          </BlankCard>
        </div>
        <AssignToModal
          isModalOpen={bulkActions.isModalOpen}
          emails={bulkActions.emails}
          invoices={bulkActions.invoices}
          isCustomer={false}
          closePopup={onClosePopup}
        ></AssignToModal>
      </Layout>
    );
  } else {
    return (
      <>
        <Row>
          <Col span={1} />
          <Col span={22} className="container">
            <Row>
              <Col span={24}>
                <SearchBar
                  tags={searchTags}
                  serviceTeamTags={serviceTeamTags}
                  statusCounts={invoiceStatusCounts}
                  filter={filter}
                  onFilterChange={onFilterChange}
                  onSearch={handleOnSearchTags}
                  onSearchServiceTeam={handleOnSearchServiceTeamTags}
                  showServiceTeamDropdownSearch={showServiceTeamDropdownSearch}
                  showDocumentTypeDropdown={features?.showDocumentTypeDropdown}
                  onDocumentTypeChange={handleDocumentTypeChange}
                  documentType={documentType}
                />
              </Col>
            </Row>
            <Row className="pt-20px">
              <Col span={24}>
                {displayEmailList && (
                  <EmailsTable
                    filter={filter}
                    onFilterChange={onFilterChange}
                    selectedEmails={bulkActions.emails}
                    onSelectionChange={onSelectionChange}
                  />
                )}
                {!displayEmailList && (
                  <InvoicesTable
                    filter={filter}
                    onFilterChange={onFilterChange}
                    selectedEmails={bulkActions.emails}
                    onSelectionChange={onSelectionChange}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col span={2} />
        </Row>
      </>
    );
  }
}

export default Dashboard;
