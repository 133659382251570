import { Col, Row, Tag, Tooltip, Select } from "antd";
import { Filter } from "../pages/Dashboard";
import StatusFilters from "./StatusFilters";
import { StatusCounts } from "../utils";
import TagInput from "./InputWithTags";
import DebounceSelect from "./DebounceSelect";
import { searchServiceTeams } from "../store/serviceTeamSlice";
import { DocumentTypeEnum } from "../enums/DocumentTypeEnum";

const { Option } = Select;

interface Props {
  tags: string[];
  serviceTeamTags: string[];
  statusCounts: StatusCounts[];
  filter: Filter;
  showServiceTeamDropdownSearch: boolean;
  showDocumentTypeDropdown: boolean;
  onFilterChange: (key: keyof Filter, value?: number | string) => void;
  onSearch: (criteria: string[]) => void;
  onSearchServiceTeam: (criteria: string[]) => void;
  onDocumentTypeChange: (value: DocumentTypeEnum) => void;
  documentType: DocumentTypeEnum;
}

const SearchStyle = {
  display: "flex",
  flex: 1,
};

function SearchBar({
  tags,
  serviceTeamTags,
  statusCounts,
  filter,
  onFilterChange,
  onSearch,
  onSearchServiceTeam,
  showServiceTeamDropdownSearch,
  showDocumentTypeDropdown,
  onDocumentTypeChange,
  documentType
}: Props) {
  const fetchServiceTeamList = async (criteria: string): Promise<any[]> => {
    return searchServiceTeams(criteria).then((res: any) => {
      return res;
    });
  };

  const filterByServiceTeam = (value: any) => {
    const newArr = serviceTeamTags;
    if (value && value.value) {
      if (!newArr.includes(value.value)) {
        newArr.push(value.value);
      }
    }

    onSearchServiceTeam(newArr);
  };

  const handleDelete = (tag: string) => {
    const newArr = serviceTeamTags.filter(st => st !== tag);
    onSearchServiceTeam(newArr);
  };

  const handleDocumentTypeChange = (value: DocumentTypeEnum) => {
    onDocumentTypeChange(value);
  };

  return (
    <div className="search-bar">
      <Row gutter={8}>
        <Col span={12} className="fw-800 fs-12px">
          Search
        </Col>
        <Col span={12} className="fw-800 fs-12px">
          Status
        </Col>
      </Row>
      <Row gutter={8} className="mt-5px">
        <Col span={showServiceTeamDropdownSearch || showDocumentTypeDropdown ? 6 : 8}>
          <TagInput value={tags} onSearch={onSearch} />
        </Col>
        {showServiceTeamDropdownSearch && (
          <Col span={3}>
            <Row>
              <Col span={24} className="mb-5px">
                <DebounceSelect
                  size="middle"
                  value={null}
                  placeholder="Service Team"
                  fetchOptions={fetchServiceTeamList}
                  onChange={filterByServiceTeam}
                  style={SearchStyle}
                />
              </Col>
              <Col span={24}>
                {serviceTeamTags.map((item) =>
                  item.length > 20 ? (
                    <Tooltip title={item} key={item}>
                      <Tag className="m-3px" closable onClose={() => handleDelete(item)}>{`${item.slice(0, 20)}...`}</Tag>
                    </Tooltip>
                  ) : (
                    <Tag className="m-3px" closable key={item} onClose={() => handleDelete(item)}>
                      {item}
                    </Tag>
                  )
                )}
              </Col>
            </Row>
          </Col>
        )}
        {showDocumentTypeDropdown && (
            <Col span={3}>
            <Select
              placeholder="Document Type"
              onChange={handleDocumentTypeChange}
              style={{ width: "100%" }}
              value={documentType}
              defaultValue={null}
            >
              <Option value={DocumentTypeEnum.Invoice}>PO</Option>
              <Option value={DocumentTypeEnum.StockInvoice}>Stock PO</Option>
              <Option value={DocumentTypeEnum.Expense}>Expense</Option>
              <Option value={DocumentTypeEnum.MultiPage}>Multi-page</Option>
              <Option value={DocumentTypeEnum.None}>None</Option>
              <Option value={null}>All</Option>
            </Select>
            </Col>
        )}
        <Col span={12} offset={showServiceTeamDropdownSearch && showDocumentTypeDropdown ? 0 : showServiceTeamDropdownSearch || showDocumentTypeDropdown ? 3 : 4}>
          <StatusFilters statusCounts={statusCounts} filter={filter} onFilterChange={onFilterChange} />
        </Col>
      </Row>
    </div>
  );
}

export default SearchBar;